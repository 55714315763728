import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import classNames from '../utils/classNames';

function ImgGrid({ data, cols }) {
  return (
    <ul
      className={`s-grid-cols-${cols} space-y-12 sm:grid sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8`}
    >
      {data.map((item, index) => (
        <li key={index}>
          <div className="relative">
            <GatsbyImage
              image={item.image.childImageSharp.gatsbyImageData}
              alt={item.title}
            />
          </div>
        </li>
      ))}
    </ul>
  );
}
export default ImgGrid;
