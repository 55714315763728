import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import ImgGrid from '../components/imgGrid';
import Seo from '../components/seo';

export default function CurrentWorkPage({ data }) {
  const { projects } = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <Seo
        title="Current Design Work"
        description="We’re busy at work! Here’s a look at some of our newest projects"
      />
      <div className="page-template">
        <div className="flex flex-col">
          <p className="my-4 text-2xl text-primary">
            We’re busy at work! Here’s a look at some of our newest projects
          </p>
          <ImgGrid data={projects} cols={2} />
          <div className="mx-auto mt-12 max-w-2xl lg:max-w-4xl">
            <figure>
              <blockquote className="font-base text-center text-xl leading-8 text-gray-900 sm:text-3xl sm:leading-9">
                <p>"Design is intelligence made visible." -- Alina Wheeler</p>
              </blockquote>
            </figure>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    markdownRemark(
      frontmatter: { slug: { eq: "current-projects" }, type: { eq: "project" } }
    ) {
      frontmatter {
        projects {
          title
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
